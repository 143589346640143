<template>
    <div>
        <main-layout></main-layout>
        <v-container class="wrapper">
            <v-form>
                <h2 class="text-center">{{ $t("account.title") }}</h2>
                <hr />

                <label class="mt-3">{{ $t("account.phoneNumber") }}</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" disabled v-model="phoneNumber" />

                <label class="mt-3">{{ $t("account.fullName") }}</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" v-model="fullname" />
                <b-form-invalid-feedback class="pb-2" v-if="saving" :state="isValidFullname">
                    {{ $t("message.invalidFullname") }}
                </b-form-invalid-feedback>

                <label class="mt-3">{{ $t("account.email") }}</label>
                <span class="star-require"></span>
                <input class="form-control form-control-submit" v-model="email" />
                <b-form-invalid-feedback class="pb-2" v-if="saving" :state="isValidEmail">
                    {{ $t("message.invalidEmail") }}
                </b-form-invalid-feedback>

                <label class="mt-3">{{ $t("account.dateOfBirth") }}</label>
                <div>
                    <date-picker :editable="false" format='DD-MM-YYYY' v-model="dateOfBirth" />
                </div>

                <div class="row mt-5">
                    <div class="col-6">
                        <button class="button--secondary" @click="back">{{ $t("button.back") }}</button>
                    </div>
                    <div class="col-6">
                        <button class="button--primary button--right" @click="updateProfile">{{ $t("button.update") }}</button>
                    </div>
                </div>
            </v-form>
        </v-container>
        <loader v-show="loading"></loader>
    </div>
</template>
<script>
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapState, mapGetters } from "vuex";
import moment from 'moment';
import commonFunc from "@/common/commonFunc"
import popup from "@/components/mixins/popup";
import Loader from "@/components/Loader.vue";

export default {
    name: "Account",
    components: {
        MainLayout,
        DatePicker,
        Loader
    },
    mixins: [screen, popup],
    // mixins: [useVerifyOTP, popup],

    data() {
        return {
            loading: false,
            // recaptchaContainer: RECAPTCHA_CONTAINER,
            phoneNumber: null,
            fullname: null,
            email: null,
            dateOfBirth: null,
            saving: false
        };
    },

    // beforeRouteEnter(to, from, next) {
    //     window.scrollTo(0,0);
    //     store.state.auth.allowAnonymous = false;
    //     next();
    // },

    computed: {
        ...mapState(["auth"]),
        ...mapGetters({
            authDataGet: "auth/authDataGet"
            // authToken: "auth/authToken",
        }),

        isValidFullname() {
            return this.fullname?.length > 0;
        },
        isValidEmail() {
            return commonFunc.isValidEmail(this.email);
        },
    },
    mounted() {
        this.phoneNumber = this.authDataGet.phone;
        this.email = this.authDataGet.email ? this.authDataGet.email : 'not registered';
        this.fullname = this.authDataGet.fullname;
        this.dateOfBirth = this.authDataGet.dateOfBirth ? new Date(moment(this.authDataGet.dateOfBirth, "YYYY-MM-DD")) : null;
        console.log('this.dateOfBirth', this.dateOfBirth)
    },

    methods: {
        validate() {
            if (!this.isValidFullname) {
                this.$refs.fullname.focus();
                return false;
            }
            
            if (!this.isValidEmail) {
                this.$refs.fullname.focus();
                return false;
            }

            return true;
        },

        back(e){
            e.preventDefault();
            return this.$router.push('/marketing');
        },

        async updateProfile(e) {
            e.preventDefault();
            this.saving = true; 
            this.loading = true
            const isValid = this.validate();
            if (!isValid) return;

            if (this.dateOfBirth) {
                await this.$store.dispatch("auth/customerUpdateProfile", {
                    username: this.username,
                    full_name: this.fullname,
                    date_of_birth: moment(this.dateOfBirth).format("YYYY-MM-DD"),
                    gender: this.authDataGet.gender,
                    email:this.email,
                });
            } else {
                await this.$store.dispatch("auth/customerUpdateProfile", {
                    username: this.username,
                    full_name: this.fullname,
                    gender: this.authDataGet.gender,
                    email:this.email,
                });
            }

            if (this.auth.error) {
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.auth.error, 
                    this.messageBoxDanger
                );
            } else {
                this.loading = false;
                return this.$bvModal.msgBoxOk(
                    this.$t("message.updateAccount"),
                    this.messageBoxOK
                )
            }
        },
    },
};
</script>