import { mapGetters, mapActions, mapMutations } from "vuex"

export default {
	data() {
		return {
			informationMsg: "",
			formValid: false,
			formActive: true,
		}
	},
	computed: {
		...mapGetters({
			isLoading: "screen/isLoading",
			error: "screen/error",
			locale: "locale/locale",
		}),
	},
	watch: {
		locale() {
			this.formActive = false
			this.$nextTick(() => {
				this.formActive = true
			})
		},
	},
	methods: {
		fieldRule(rules){
			if(!this.formActive) return [];
			return rules;
		},
		...mapMutations({
			setLoading: "screen/setLoading",
			setError: "screen/setError",
		}),
		...mapActions({
			resetState: "screen/resetState",
		}),
	},
}
